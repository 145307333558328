<!--
 * @Author: zhounianlai
 * @Date: 2021-05-27 17:18:50
 * @LastEditTime: 2021-06-07 18:49:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \yue_quanzhan_h5\src\views\localLife\comeOn\h5\conpon\index.vue
-->
<template>
  <div class="content">

    <!--<div class="bg-img-con">-->
    <!--  <img style="display: none" :src="url + imgList[2]">-->
    <!--  <img style="display: none" :src="url + imgList[3]">-->
    <!--  <img style="display: none" :src="url + imgList[4]">-->
    <!--</div>-->
    <div class="content-img" />
    <div class="content-mian">
      <div class="bg-coupon-con bg">
        <template v-if="couponList.length > 0">
          <div class="coupon-con">
            <div v-for="(item, index) in couponList" :key="index" class="item">
              <div v-if="item.promotionType === 1" class="item-left">
                <strong><i>¥</i>{{ item.discount }}</strong>
                <span>满{{ item.amount }}元</span>
              </div>
              <div v-if="item.promotionType === 2" class="item-left">
                <strong><i>¥</i>{{ item.discount }}</strong>
                <span>立减</span>
              </div>
              <div v-if="item.promotionType === 3" class="item-left">
                <strong>{{ item.discount }} <i>折</i></strong>
                <span>折扣</span>
              </div>
              <div class="item-right">
                <strong>{{ item.promotionDesc }}</strong>
                <span>有效期至{{ item.endTimestamp }}</span>
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="coupon-null">
            <img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-13/21/yuelvhuiYTzNatm1Rl1631538093.png">
            <!--<span>暂无优惠券</span>-->
          </div>
        </template>
      </div>
      <div class="bg-btn-con">
        <div class="conpon-btn" @click="goOilList">
          <img :src="url + imgList[1]">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'
Vue.use(Toast)
import { getClientInfo } from '@/utils/public.js'
import { getPromotionList, isDisplay } from '@/services/comeOn.js'
import { isYTApp } from '@/utils/common/validate'
import { routerRN } from '@/utils/app'
let num = 1
export default {
  data() {
    return {
      url:
        'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/',
      imgList: [
        '2021-05-27/17/yuelvhuiLJRRsGenJU1622106964.png',
        '2021-09-13/22/yuelvhuif34qVzQOnu1631541603.png'
      ],
      couponList: [],
      page: 1,
      mid: '',
      mobile: '',
      timer: null
    }
  },
  mounted() {
    const { mid = localStorage.getItem('uid'), mobile = localStorage.getItem('mobile') } = this.$route.query
    this.mid = mid
    this.mobile = mobile
    // 券查询接口
    this.getUserPromotion()

    // 分享
    this.shareInfo()
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  },
  methods: {

    /**
     * @description 跳转加油列表
     * @author zhounianlai
     */
    goOilList() {
      // 判断是否悦淘App
      if (isYTApp()) {
        // 跳转加油站列表的页面
        routerRN({
          redirectType: 61
        })
      } else {
        if (getClientInfo(1) === 'android' || getClientInfo(1) === 'ios') {
          const _Router = {
            eventName: 'back'
          }
          this.$store.dispatch('RNrouter', _Router)
        } else {
          this.$router.push({
            path: '/comeOnDataList'
          })
        }
      }
    },

    /**
     * @description 券查询接口
     * @author zhounianlai
     * @param {String} mid;// 用户ID
     * @param {String} mobile;// 当前登录人手机号
     */
    getUserPromotion() {
      const { mobile, mid } = this
      getPromotionList({
        mid: mid,
        mobile: mobile,
        page: this.page
      }).then((res) => {
        if (res.code === 200) {
          if (this.timer) {
            clearTimeout(this.timer)
            this.timer = null
          }
          const { totalPage, data } = res.data
          if (data.length > 0) {
            data.map((item) => {
              this.couponList.push(item)
            })
            if (this.page < totalPage) {
              this.page++
              this.getUserPromotion()
            }
          } else {
            this.couponList = []

            // 如果存在数据空的问题，重新在三秒后获取, num 为节流器只触发一次
            if (num && this.couponList <= 0) {
              num--
              this.timer = setTimeout(() => {
                this.getUserPromotion()
              }, 3000)
            }
          }
        } else {
          // Toast(res.msg);
        }
      }).catch((err) => {
        console.log(err)
      })
    },

    /**
     * @description 分享
     * @author zhounianlai
     * @param {String} codeNumber;// 邀请码
     */
    shareInfo() {
      const { mid, mobile } = this.$route.query
      const params = {
        mid: mid,
        mobile: mobile
      }

      isDisplay(params)
        .then((res) => {
          if (res.code === 200) {
            const { title, image, des, url } = res.data
            const shareInfo = {
              title: title,
              desc: des,
              link: url,
              imgUrl: image
            }
            this.$store.dispatch('wxConfigInit', shareInfo)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  padding: 0;
  font-size: 0;
  position: absolute;
  background-color: #C989DF;
  //background: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-13/20/yuelvhuica0OD7AWwD1631536218.png")
  //no-repeat;
  height: 100vh;
  //overflow-y: auto;
    //background-size: 100%;
  .content-img {
    background: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-13/20/yuelvhuica0OD7AWwD1631536218.png")
    no-repeat;
    height: 100vh;
    overflow-y: auto;
    background-size: 100%;
  }
  .content-mian {
    position: relative;
    bottom: 55%;
  }
  .bg-coupon-con {
    width: 352px;
    margin: auto;
    background: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-13/20/yuelvhuiPSfNGhzVBf1631534729.png")
    no-repeat;
    background-size: 100%;
    height: 338px;
  }
  .bg-btn-con {
    height: 165px;
    background: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-13/20/yuelvhuivCJ1B2iYnY1631534736.png")
      no-repeat;
    background-size: 100%;
    position: relative;
    margin-top: -120px;
  }
  .bg {
    background-size: 100%;
    position: relative;
  }
  .conpon-tip,
  .conpon-btn {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  }
  .coupon-null{
    position: absolute;
    left: 40px;
    top: -10px;
    right: 40px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      margin-top: -20px;
    }
    span{
      font-size: 12px;
      color: #666;
    }
  }
  .coupon-con {
    position: absolute;
    height: 160px;
    left: 40px;
    top: 64px;
    right: 40px;
    bottom: 0;
    overflow-y: auto;
    color: #d94031;
    .item {
      height: 80px;
      margin-bottom: 10px;
      display: flex;
      border-radius: 8px;
      justify-content: space-between;
      background: linear-gradient(90deg, #fdf5f2 0%, #fae3d5 100%);
      .item-left,
      .item-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .item-left {
        width: 90px;
        align-items: center;
        position: relative;
        strong {
          i {
            font-style: normal;
            font-size: 15px;
            padding-right: 5px;
          }
          font-size: 25px;
          font-weight: normal;
        }
        span {
          opacity: 0.7;
          font-size: 10px;
        }
      }
      .item-right {
        flex: 1;
        align-items: flex-start;
        padding-left: 30px;
        position: relative;
        strong {
          font-size: 20px;
          margin-bottom: 5px;
          font-weight: normal;
        }
        span {
          opacity: 0.7;
          font-size: 8px;
        }
      }

      .item-left::before {
        content: "";
        position: absolute;
        right: 0;
        width: 0.5px;
        height: 60px;
        background: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(217, 64, 49, 0.4),
          rgba(255, 255, 255, 0)
        );
      }
    }
  }

  .conpon-btn {
    bottom: 40px;
    img {
      width: 240px;
    }
  }
}
</style>
